import React from "react";
import { Helmet } from "react-helmet";
import '../styles/styles.css';

//Static
import {specialOpeningTimes} from "../components/texts/AabningstiderTexts";

//Components
import OpeningHoursTable from "../components/OpeningHoursTable";
import CollapsibleWrapper from "../components/CollapsibleWrapper";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Aabningstider = () => {
    return (
        <>
            <Helmet>
                <title>Åbningstider | Klippingeslagteren</title>
            </Helmet>
            <Navbar />
            <div className='mt-32 pb-20 place-items-center w-auto m-auto max-w-5xl md:mt-44'>
                <div className='w-full flex flex-col lg:flex-row'>
                    <div className='mx-auto pb-6 md:w-1/4'>
                        <OpeningHoursTable />
                        {/*<div className='mt-6 text-center transition ease-in-out hover:-translate-y-1 hover:text-red duration-300'>
                            <a href="https://www.google.dk/maps/place/Klippinge+Slagteren/@55.3474213,12.3225589,17z/data=!3m1!4b1!4m5!3m4!1s0x4652e479dbd275e1:0x81c87842d43f65ff!8m2!3d55.3474213!4d12.3247476">
                                <div className="uppercase font-bold underline">
                                    FIND VEJ
                                </div>
                                <img src={maps} alt='maps' width='100%' className='hidden md:block'/>
                            </a>
                        </div>*/}
                    </div>
                    <div className='mx-auto md:w-full lg:w-3/5'>
                        <CollapsibleWrapper content={specialOpeningTimes} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Aabningstider