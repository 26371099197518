import React from 'react'
export const specialOpeningTimes = [
    {
        title: 'Helligdage og Højtider',
        text: (
            <>
                <p>Klippingeslagteren holder lukket <b>ALLE</b> helligdage og højtider.
                    Dog har vi ændrede åbningstider i perioderne op til enkelte højtider,
                    hvor vi vil stå klar til at assistere med alt, hvad der hører sig til.
                </p>
                <div className='pt-6'>
                    <table className='w-full'>
                        <caption>
                            Påske
                        </caption>
                        <colgroup>
                            <col span='1' className='w-8/12' />
                            <col span='1' className='w-2/12' />
                        </colgroup>
                        <tbody>
                        <tr className='border-b'>
                            <td>Onsdag inden påske</td>
                            <td>10-16</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className='pt-6'>
                    <table className='w-full'>
                        <caption>
                            Jul og Nytår
                        </caption>
                        <colgroup>
                            <col span='1' className='w-8/12' />
                            <col span='1' className='w-2/12' />
                        </colgroup>
                        <tbody>
                        <tr className='border-b'>
                            <td>22/12</td>
                            <td>10-16</td>
                        </tr>
                        <tr className='border-b'>
                            <td>23/12</td>
                            <td>10-15</td>
                        </tr>
                        <tr className='border-b'>
                            <td>24/12 - 29/12</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b'>
                            <td>30/12</td>
                            <td>10-16</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>
        )
    },
    {
        title: 'Ferielukket',
        text: (
            <>
                <div className='text-center p-3'>
                    <h3 className='text-lg'>Vinter</h3>
                    <p>31/12 - 30/01</p>
                </div>
                <div className='text-center p-3'>
                    <h3 className='text-lg'>Påske</h3>
                    <p>Ugen efter påske</p>
                </div>
                <div className='text-center p-3'>
                    <h3 className='text-lg'>Sommer</h3>
                    <p>Uge 29 - 32</p>
                </div>
            </>
        )
    },
]